<template>
  <div style="width: 1200px;margin: 0 auto;">
    <div class="title">
      <span style="font-weight: bold; margin: 0 8px;">{{searchData.text}}</span>搜索结果
    </div>
    <ul class="search-list" v-if="searchData.keyword">
      <li v-for="(item,index) in searchResult" :key="index">
         <div class="title-box">
            <a class="title" @click="onFileClicked(item)">
              <span v-html="item.name"></span>
            </a>
            <el-tag v-if="item.tag != null ">{{item.tag[0]}}</el-tag>
            <!-- <span>{{ item.tag != null ?  item.systemTag[0] + ': ' + item.tag[0] : item.systemTag[0] + ': ' + '' }}</span> -->
        </div>
        <div class="content">
           <span v-if="item.documentContentList.length > 0 ">
              【第{{ item.documentContentList[0].pageIndex + 1}}页】
          </span>
          <span v-html="item.documentContentList.length > 0 ? item.documentContentList[0].content :'' "></span>
        </div>
      </li>
    </ul>
    <el-pagination
        @current-change="pageChanged"
        layout="prev, pager, next"
        :page-size="searchData.pageSize"
        :total="pager.total"
        style="text-align: left;"
        :style="{'margin-top': searchData.keyword ? '0px' : '30px'}">
      </el-pagination>

  </div>
</template>

<script>
import {search} from '@/api/document.js'
export default {
  data () {
    return {
      searchData: {
        // text: "",
        // pageNum: 1,
        // pageSize: 12
          docType: 0,
          keyword: "",
          pageNum: 1,
          pageSize: 10,
          systemTag: null
      },
      pager: {
        total: 0,
      },
      searchResult: []
    }
  },
  computed: {},
  watch: {
    "$route"(value){
      this.doSearch()
    }
  },
  methods: {
    doSearch(){
      this.searchData.keyword = this.$route.query.s
      
      if(this.searchData.keyword){
        //搜索内容
        search(this.searchData).then(({code, current, total, pages, size, data, message}) => {
          if(code == 0){
            this.pager.total = total
            this.searchResult = data.map(item => {
              
              item.name = item.name.replace(new RegExp(`(${this.searchData.keyword})`,"gim"), "<span class='key'>$1</span>")
              if(item.documentContentList.length > 0) {
                item.documentContentList[0].content = item.documentContentList[0].content.replace(new RegExp(`(${this.searchData.keyword})`,"gim"), "<span class='key'>$1</span>")

                let strings = item.documentContentList[0].content;
                let prevStrings;
                if(strings.indexOf(this.searchData.keyword) > 50) {
                  prevStrings = strings.slice(strings.indexOf(this.searchData.keyword) - 50 ,strings.indexOf(this.searchData.keyword))
                } else {
                  prevStrings =  strings.slice(0 ,strings.indexOf(this.searchData.keyword))
                }
                let nextStrings = strings.slice(strings.indexOf(this.searchData.keyword), strings.indexOf(this.searchData.keyword) + 50)
                item.documentContentList[0].content = prevStrings + nextStrings
               
              }

              return item
            })
          }
        })
      }else{
        this.pager.total = 0;
      }
    },

    onFileClicked(item){
      //打开文件
       if(item.documentId !=null){
          item.id = item.documentId
        }
        this.$ebus.$emit('publicReader',{
            shareId:item.shareId, 
            id:item.documentId || item.id,
            documentId: item.documentId,
            name:item.name
        })
    },

    pageChanged(num){
      this.searchData.pageNum = num
      this.doSearch()
    }
  },
  created () {
    //触发搜索
    this.doSearch()
  },
  mounted () {

  },
}
</script>
<style>
  .key{
    font-weight: bold;
    color: #032aa8
  }
</style>
<style lang='less' scoped>
.search-list{
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;

  li{
    padding: 10px;
    line-height: 23px;

    .title{
      text-decoration: underline;
      cursor: pointer;
    }
     .title-box {
      display: flex;
      justify-content: space-between;
    }

    .content{
      margin-top: 10px;
    }
  }
}
</style>